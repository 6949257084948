import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import { Product } from '@commerce/types/product';
import type { ModuleCrossSellProducts } from '@components/common/types/ModuleCrossSellProducts';
import { getProductsBySKUs } from '@components/product/helpers';
import CrossSellProducts from '@components/product/ProductView/CrossSellProducts/CrossSellProducts';
import { Renderer } from '@components/screen/factory';

type Props = Omit<ModuleCrossSellProducts, 'intenalName'>;

const ModuleCrossSellProducts: FC<Props> = ({ moduleProduct, blockTitle }) => {
  const { t } = useTranslation(['common', 'product']);
  const title = blockTitle || t('product:header.crossSell');

  const [productData, setProductData] = useState<Product[] | null>(null);
  const router = useRouter();
  const productsSku = moduleProduct?.length ? moduleProduct.map(({ content }) => content.sku) : null;

  useEffect(() => {
    async function getProduct(SKUs: string[]) {
      const products = await getProductsBySKUs(SKUs, router.locale);

      if (products?.length > 0) {
        // sort by original order (as in Contentful)
        // we cut off OOS products
        setProductData([
          ...products
            .sort(
              (firstProduct, secondProduct) =>
                SKUs.indexOf(firstProduct.sku as string) - SKUs.indexOf(secondProduct.sku as string)
            )
            .filter((product) => !!product?.inventory?.isInStock),
        ]);
      }
    }

    if (productsSku && !productData) {
      getProduct(productsSku);
    }
  }, [productData, productsSku, router.locale]);

  if (moduleProduct?.length === 0 || !productData) {
    return null;
  }

  return <CrossSellProducts title={title} products={productData} shouldOpenCartOverlay={false} />;
};

export default Renderer({ name: 'moduleCrossSellProducts' })(ModuleCrossSellProducts);
