import cn from 'classnames';
import { FC, memo } from 'react';

import { ModulePlansAndPricingMembershipPlan } from '@components/common/types/ModulePlansAndPricing';
import Button from '@components/ui/Button/Button';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';

import styles from './PlansAndPricing.module.scss';

export enum MembershipPeriodOptions {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
}

type Props = Omit<ModulePlansAndPricingMembershipPlan, 'internalName'> & {
  period: MembershipPeriodOptions;
  className: string;
};

const PlanCard: FC<Props> = ({
  badge,
  heading,
  monthlyPrice,
  yearlyPrice,
  benefitsTitle,
  benefitsList,
  additionalOfferImage,
  additionalOfferText,
  ctaText,
  annualCtaLink,
  monthlyCtaLink,
  ctaOpenNewTab,
  period,
  className,
}) => {
  const price = period === MembershipPeriodOptions.Yearly ? yearlyPrice : monthlyPrice;
  const link = period === MembershipPeriodOptions.Yearly ? annualCtaLink : monthlyCtaLink;

  return (
    <div className={cn(className, styles.planCard)}>
      {badge && <span className={styles.badge}>{badge}</span>}
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {price && <h4 className={styles.price}>{price}</h4>}
      {benefitsTitle && <p className="font-bold mb-2">{benefitsTitle}</p>}
      {benefitsList && <Text asElement="div" html={benefitsList} className={styles.benefits} />}
      <div className="mt-auto">
        {additionalOfferImage && (
          <div className={styles.offerContainer}>
            {renderImage(
              { url: additionalOfferImage.url, alt: additionalOfferImage.alt },
              { width: 70, height: 70, loading: 'lazy', className: 'inline mr-1' }
            )}
            <Text asElement="span" html={additionalOfferText} className={styles.additionalOffer} />
          </div>
        )}
        {link && ctaText && (
          <Button variant="cta" href={link.toString()} target={ctaOpenNewTab ? '_blank' : '_self'}>
            {ctaText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(PlanCard);
