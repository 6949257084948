import { Teaser } from '@components/common/types/Teaser';
import Text from '@components/ui/Text/Text';

import s from './PartnersInfo.module.scss';

const PartnersInfo = ({ paragraph }: Teaser) => (
  <div className="pt-7">
    <Text html={paragraph} className={s.description} />
  </div>
);

export default PartnersInfo;
