import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import styles from './PlansAndPricing.module.scss';

type Props = { options: string[]; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void };

const PlanToggle: FC<Props> = ({ options, onChange }) => {
  const { t } = useTranslation(['plans']);

  return (
    <label className={styles.planToggle} title={t('plans:choosePlans.planToggle.label.title')} htmlFor="checkbox">
      <input id="checkbox" type="checkbox" onChange={onChange} className={`${styles.toggleInput} peer`} />

      {/* Monthly Plan (Unchecked by default) */}
      <span
        className={`${styles.toggleOption} peer-[:not(:checked)]:bg-[--dark-purple] peer-[:not(:checked)]:text-white peer-checked:text-black`}
      >
        {options[1]}
      </span>

      {/* Yearly Plan (Checked by default) */}
      <span
        className={`${styles.toggleOption} peer-checked:bg-[--dark-purple] peer-checked:text-white peer-[:not(:checked)]:text-black`}
      >
        {options[0]}
      </span>
    </label>
  );
};

export default PlanToggle;
