import { Entry } from '@services/contentful/types';

import { Image, Video } from '@commerce/types/common';

export const STYLE_VARIANT = {
  DEFAULT: 'default',
  SLIDER: 'slider',
};

export interface ModulePillBoxes {
  internalName: string;
  eyebrow: string;
  header: string;
  pills: Entry<ModulePillBoxItem>[];
  styleVariant: string;
}

export interface ModulePillBoxItem {
  internalName: string;
  buttonText?: string;
  image?: Image;
  video?: Video;
  header?: string;
  bottomText: string;
  index: number;
  styleVariant: string;
  defaultAlignment: boolean;
  roundCorners: boolean;
}
