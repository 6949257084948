import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { Product } from '@commerce/types/product';
import ProductCard from '@components/product/ProductListing/ProductCard/ProductCard';
import { GlobalQueryString } from '@components/ui/context';
import LoadingDots from '@components/ui/LoadingDots/LoadingDots';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { useAddToCart } from '@lib/hooks/useAddToCart';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';

import { ProductCategorySlug } from '../../enums';

import style from './CrossSellProducts.module.scss';

enum CardsNumOnDevice {
  Desktop = 5,
  Tablet = 4,
  Mobile = 2,
}

interface Props {
  products: Product[];
  title?: string;
  titleStyle?: string;
  loading?: boolean;
  shouldOpenCartOverlay?: boolean;
}

const CrossSellProducts: FC<Props> = ({
  products,
  title: blockTitle,
  titleStyle,
  loading = false,
  shouldOpenCartOverlay = true,
}) => {
  const { t } = useTranslation(['common', 'product']);
  const isMobile = useMatchMedia('768px');
  const isTablet = useMatchMedia('1280px');
  const productCardsPerView = isMobile
    ? CardsNumOnDevice.Mobile
    : isTablet
      ? CardsNumOnDevice.Tablet
      : CardsNumOnDevice.Desktop;

  const title = blockTitle || t('product:header.crossSell');

  const imgProps = { loading: 'lazy', width: '480', height: '480' } as const;

  const onAddToCart = useAddToCart({ shouldOpenCartOverlay })[1];

  const router = useRouter();

  const { query } = router;

  const hasInAppQuery = query[GlobalQueryString.IN_APP] === '1';

  if (loading) {
    return <LoadingDots className="justify-center my-15 flex" />;
  }

  const finalProducts = hasInAppQuery
    ? products.filter((product) => product?.customFields?.template !== ProductCategorySlug.gps)
    : products;

  const enableControls = finalProducts.length > productCardsPerView;

  return (
    !!finalProducts?.length && (
      <Section className={cn(style.carouselSection)} mobileNoPadding>
        <Text variant="heading-2" className={cn(style.header, titleStyle)}>
          {title}
        </Text>
        <Swiper
          modules={[Navigation]}
          className="px-5"
          slidesPerView={CardsNumOnDevice.Desktop}
          spaceBetween={20}
          loop
          navigation={
            enableControls
              ? {
                  prevEl: '.nav-prev',
                  nextEl: '.nav-next',
                }
              : false
          }
          breakpoints={{
            320: {
              slidesPerView: CardsNumOnDevice.Mobile,
            },
            768: {
              slidesPerView: CardsNumOnDevice.Tablet,
            },
            1280: {
              slidesPerView: CardsNumOnDevice.Desktop,
            },
          }}
        >
          {finalProducts.map((product: Product) => (
            <SwiperSlide key={`${product.path}-${product.id}`}>
              <ProductCard product={product} onAddToCart={onAddToCart} imgProps={imgProps} showAddToCartCTA />
            </SwiperSlide>
          ))}
          {enableControls && (
            <div className={style.arrowContainer}>
              <button
                type="button"
                className={cn(style.arrow, 'nav-prev absolute left-0 cursor-pointer z-10')}
                aria-label={t('common:screenReader.previousSlide')}
                title={t('common:screenReader.previousSlide')}
              >
                <FontAwesomeIcon icon={faChevronLeft} size="2x" title={t('common:screenReader.previousSlide')} />
              </button>
              <button
                type="button"
                className={cn(style.arrow, 'nav-next absolute right-0 cursor-pointer z-10')}
                aria-label={t('common:screenReader.nextSlide')}
                title={t('common:screenReader.nextSlide')}
              >
                <FontAwesomeIcon icon={faChevronRight} size="2x" title={t('common:screenReader.nextSlide')} />
              </button>
            </div>
          )}
        </Swiper>
      </Section>
    )
  );
};

export default CrossSellProducts;
