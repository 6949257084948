import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';

import s from './FeaturedReviews.module.scss';

interface Review {
  comment: string;
  authorFirstName: string;
  variation: string;
}

interface FeaturedReviewsProps {
  eyebrow?: string;
  richTitle?: string;
  body?: string;
  footerTitle?: string;
  cta?: string;
  url?: string;
  moduleReviews: Entry<Review>[];
  useWhiteBg?: boolean;
}

const icons: Record<string, Record<string, string>> = {
  paw: { path: '/svgs/review/paw_icon.svg', height: '26px', width: '27px' },
  key: { path: '/svgs/review/key_icon.svg', height: '15px', width: '28px' },
  briefcase: { path: '/svgs/review/briefcase_icon.svg', height: '26px', width: '28px' },
  quotes: { path: '/svgs/review/quotes_icon.svg', height: '26px', width: '28px' },
};

const Reviews: FC<{ reviews: Entry<Review>[] }> = ({ reviews }) => {
  return (
    <div className={s.wrap}>
      {reviews.map((review: Entry<Review>) => (
        <div key={review.content.authorFirstName} className={s.review}>
          {icons[review.content.variation] && (
            <div className={s.reviewIcon}>
              {renderImage(
                {
                  url: icons[review.content.variation].path,
                  alt: review.content.comment,
                },
                {
                  'aria-hidden': true,
                  loading: 'lazy',
                  width: icons[review.content.variation].width,
                  height: icons[review.content.variation].height,
                }
              )}
            </div>
          )}
          <Text className={s.reviewText} html={review.content.comment} />
          <Text className={s.author} color="var(--dark-purple)">
            — {review.content.authorFirstName}
          </Text>
        </div>
      ))}
    </div>
  );
};

const FeaturedReviews: FC<FeaturedReviewsProps> = ({
  eyebrow,
  richTitle,
  body,
  footerTitle,
  cta,
  url,
  moduleReviews,
  useWhiteBg = false,
}) => {
  return (
    <Section className={cn(useWhiteBg ? 'bg-white' : 'bg-bone')}>
      {eyebrow && (
        <Text color="var(--eggplant)" className={cn(s.headline, 'mb-3 lg:mb-3')} variant="eyebrow" html={eyebrow} />
      )}

      {richTitle && <Text className={cn('text-primary', s.subheader)} variant="header-4" html={richTitle} />}

      {body && <Text className={cn('text-primary mt-5 lg:mt-7', s.copy)} html={body} />}

      <Reviews reviews={moduleReviews} />

      {footerTitle && (
        <Text color="var(--eggplant)" className={cn(s.headline, 'mt-7 lg:mt-10')} variant="eyebrow">
          {footerTitle}
        </Text>
      )}

      {cta && url && (
        <div className="flex justify-center mt-7">
          <Button variant="cta" href={url}>
            {cta}
          </Button>
        </div>
      )}
    </Section>
  );
};

export default Renderer({ name: 'moduleFeaturedCustomerReviews' })(FeaturedReviews);
