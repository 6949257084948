import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useMemo, useState } from 'react';

import Partner from '@components/common/Partner/Partner';
import { ModulePartner } from '@components/common/types/ModulePartner';
import { Teaser } from '@components/common/types/Teaser';
import Button from '@components/ui/Button/Button';
import Select from '@components/ui/Select/Select';
import Text from '@components/ui/Text/Text';
import { useMatchTailwindMedia } from '@lib/hooks/useMatchMedia';

import s from './PartnersContainer.module.scss';

export interface Props {
  partners: ModulePartner[];
  moduleTeaser?: Teaser;
  className?: string;
}

const PartnersContainer: FC<Props> = ({ partners, moduleTeaser, className }) => {
  const { t } = useTranslation(['common']);
  const [selectedTab, setSelectedTab] = useState(t('common:all').toString());
  const isMobile = useMatchTailwindMedia('lg');

  const partnersByCategory: {
    [category: string]: ModulePartner[];
  } = useMemo(
    () =>
      partners.reduce(
        (
          res: {
            [category: string]: ModulePartner[];
          },
          c: ModulePartner
        ) => {
          const category = c.category || t('common:all');
          (res[category] = res[category] || []).push(c);

          return res;
        },
        { [t('common:all')]: [] }
      ),
    [partners, t]
  );

  const selectOptions = useMemo(
    () => Object.keys(partnersByCategory).map((category) => ({ key: category, value: category })),
    [partnersByCategory]
  );

  return (
    <div className={className}>
      {moduleTeaser?.headline && moduleTeaser?.title && (
        <>
          <Text variant="heading-4" className={s.headline} asElement="h2">
            {moduleTeaser.headline}
          </Text>
          <Text variant="heading-2" className={s.title} asElement="h3">
            {moduleTeaser.title}
          </Text>
        </>
      )}
      {isMobile ? (
        <>
          <Select
            className={s.selectRoot}
            id="categorySelector"
            defaultValue={selectedTab}
            label=""
            placeholder=""
            options={selectOptions}
            onChange={(e) => setSelectedTab(e.target.value)}
          />
          <div className="flex flex-col">
            {selectedTab === '' || selectedTab === t('common:all') ? (
              <>
                {partners.map(
                  (p) =>
                    p.internalName && (
                      <div className="text-center py-4" key={p.internalName}>
                        <Partner {...p} />
                      </div>
                    )
                )}
              </>
            ) : (
              <>
                {(partnersByCategory[selectedTab] || []).map(
                  (p) =>
                    p.internalName && (
                      <div className="text-center py-4" key={p.internalName}>
                        <Partner {...p} />
                      </div>
                    )
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="justify-center flex relative pt-4">
            {selectOptions.map(({ value }) => (
              <Button
                className={cn(s.tab, { [s.selectedTab]: selectedTab === value })}
                key={value}
                onClick={() => setSelectedTab(value)}
              >
                {value}
              </Button>
            ))}
          </div>

          <div className="p-6">
            {selectedTab === t('common:all') && (
              <div className="flex flex-row flex-wrap">
                {partners.map(
                  (p) =>
                    p.internalName && (
                      <div className="w-1/3 text-center py-4" key={p.internalName}>
                        <Partner {...p} />
                      </div>
                    )
                )}
              </div>
            )}
          </div>

          {Object.keys(partnersByCategory)
            .slice(1)
            .map(
              (category: string) =>
                selectedTab === category && (
                  <div className="flex flex-row flex-wrap" key={category}>
                    {partnersByCategory[category].map(
                      (p) =>
                        p.internalName && (
                          <div className="w-1/3 text-center py-4" key={p.internalName}>
                            <Partner {...p} />
                          </div>
                        )
                    )}
                  </div>
                )
            )}
        </>
      )}
    </div>
  );
};

export default PartnersContainer;
