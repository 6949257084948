import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { getContentfulImgSet, renderResponsiveImage } from '@lib/image';

import { ModuleUseCases, ModuleUseCasesItem, ModuleUseCasesUnmappedContent } from '../../common/types/ModuleUseCases';
import Button from '../Button/Button';
import Link from '../Link';

import style from './UseCases.module.scss';

type Props = ModuleUseCases;

// On mobile, text goes above the image
const UseCaseItem: FC<ModuleUseCasesItem> = ({ headline, description, image, lazyLoadImage = false, url }) => {
  const caseItem = (
    <div className="flex flex-col">
      <div className="rounded-lg overflow-hidden">
        {renderResponsiveImage(getContentfulImgSet(image, undefined, ['xs', 'sm']), {
          ...image,
          className: 'aspect-[311/180] md:aspect-[344/180] object-cover transition duration-150 ease-linear',
          loading: lazyLoadImage ? 'lazy' : 'eager',
          height: 235,
          width: 448,
        })}
      </div>
      <Text variant="header-5-book" className={cn(style.caseTitle, 'transition duration-150 ease-linear')}>
        {headline}
      </Text>
      {description && (
        <Text variant="base" className={style.caseDescription}>
          {description}
        </Text>
      )}
    </div>
  );
  return url ? (
    <Link href={url} aria-label={headline} prefetch={false}>
      {caseItem}
    </Link>
  ) : (
    caseItem
  );
};

const UseCases: FC<Props> = ({ headline, eyebrow, description, items, ctaText, ctaUrl, hasBackground }) => {
  return (
    <Section className={!hasBackground ? 'bg-bone' : ''}>
      {eyebrow && (
        <Text variant="base-bold" className={style.eyebrow}>
          {eyebrow}
        </Text>
      )}
      {headline && (
        <Text variant="header-3" className={style.title}>
          {headline}
        </Text>
      )}
      {description && (
        <Text variant="header-5-book" className={style.subtitle}>
          {description}
        </Text>
      )}
      <div className="grid gap-y-10 sm:gap-y-6 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3">
        {items.map((item) => (
          <div key={item.internalName} className={style.useCaseItem}>
            <UseCaseItem {...item} />
          </div>
        ))}
      </div>
      {ctaText && ctaUrl && (
        <div className="py-4 text-center">
          <Button href={ctaUrl} variant="cta">
            {ctaText}
          </Button>
        </div>
      )}
    </Section>
  );
};

const mapper = ({ items, ...rest }: ModuleUseCasesUnmappedContent): Props => ({
  ...rest,
  items: mapContent(items),
});

export default Renderer({ name: 'moduleUseCases', mapper })(UseCases);
