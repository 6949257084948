import { Entry } from '@services/contentful/types';
import React, { FC } from 'react';

import Logo from '@components/common/Logo/Logo';
import { ModuleLogo } from '@components/common/types/ModuleLogo';
import { ContentModule } from '@components/common/types/Page';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Section from '@components/ui/Section/Section';

import s from './FeaturedPartnerLogos.module.scss';

interface Props extends ContentModule {
  mainLogo?: ModuleLogo;
  logos: ModuleLogo[];
}

interface RendererProps extends ContentModule {
  mainLogo: Entry<ModuleLogo>;
  logos: Entry<ModuleLogo>[];
}

const FeaturedPartnerLogos: FC<Props> = ({ mainLogo, logos }) => {
  return (
    <Section>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
        {mainLogo && (
          <Logo
            className="col-span-2 row-span-2"
            source={{
              type: 'image',
              url: mainLogo.image?.url || '',
              alt: mainLogo.title,
              className: `h-full object-cover ${s.hoverOverlay}`,
            }}
            name={mainLogo.title}
            showName={mainLogo.showTitle}
            url={mainLogo.url}
          />
        )}

        {logos.map(({ internalName, image, title, showTitle, url }: ModuleLogo) => (
          <React.Fragment key={internalName}>
            <Logo
              source={{
                type: 'image',
                url: image?.url || '',
                alt: title,
                className: `h-full object-cover ${s.hoverOverlay}`,
              }}
              name={title}
              showName={showTitle}
              url={url}
            />
          </React.Fragment>
        ))}
      </div>
    </Section>
  );
};

const mapper = ({ mainLogo, logos, ...rest }: RendererProps): Props => ({
  ...rest,
  mainLogo: mapContent(mainLogo),
  logos: mapContent(logos),
});

export default Renderer({ name: 'moduleFeaturedPartnerLogos', mapper })(FeaturedPartnerLogos);
