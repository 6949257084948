import { Entry } from '@services/contentful/types';
import { FC } from 'react';

import { Teaser } from '@components/common/types/Teaser';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Section from '@components/ui/Section/Section';

import PartnersInfo from './PartnersInfo';

interface RendererProps {
  moduleTeaser: Entry<Teaser>;
}

type Props = {
  moduleTeaser?: Teaser;
};

const PartnersInfoRenderer: FC<Props> = ({ moduleTeaser }) => {
  return (
    <>
      {moduleTeaser?.paragraph && (
        <Section>
          <PartnersInfo {...moduleTeaser} />
        </Section>
      )}
    </>
  );
};

const mapper = ({ moduleTeaser, ...rest }: RendererProps): Props => ({
  ...rest,
  moduleTeaser: mapContent(moduleTeaser),
});

export default Renderer({ name: 'modulePartnersInfo', mapper })(PartnersInfoRenderer);
