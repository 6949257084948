import { FC, useMemo } from 'react';

import CrossSellProducts from '@components/product/ProductView/CrossSellProducts/CrossSellProducts';
import { Renderer } from '@components/screen/factory';

import TurnToCommentDisplay from '../TurnToCommentDisplay/TurnToCommentDisplay';
import { GenericContainerProps } from '../types/GenericContainer';

const GenericContainer: FC<GenericContainerProps> = ({ moduleType, header, crossSellProductsContent, sku }) => {
  const { show: showCrossSell, loading: crossSellLoading, data: crossSellProducts } = crossSellProductsContent || {};

  const crossSellProductsWithoutOOS = useMemo(
    () => crossSellProducts?.filter((p) => !!p?.inventory?.isInStock),
    [crossSellProducts]
  );

  const renderContainer = () => {
    switch (moduleType) {
      case 'crossSellProducts':
        return (
          showCrossSell && (
            <CrossSellProducts products={crossSellProductsWithoutOOS || []} loading={crossSellLoading} title={header} />
          )
        );
      case 'turnToCommentsDisplay':
        return <TurnToCommentDisplay header={header} sku={sku} />;
      default:
        return <></>;
    }
  };

  return renderContainer();
};

export default Renderer({ name: 'moduleGenericContainer' })(GenericContainer);
