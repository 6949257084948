import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Renderer } from '@components/screen/factory';
import Text from '@components/ui/Text/Text';

interface GenericGoogleFormProps {
  title?: string;
  iframeUrl: string;
  width?: string;
  height?: string;
  redirectUrl?: string;
}

const GenericGoogleForm: React.FC<GenericGoogleFormProps> = ({ title, iframeUrl, width, height, redirectUrl }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iFrameheight, setIFrameHeight] = useState<string | undefined>(height);
  const router = useRouter();

  const [iframeCounter, setIFrameCounter] = useState<number>(0);

  useEffect(() => {
    if (iframeCounter === 2) {
      // form Submitted
      if (redirectUrl) {
        router.push(redirectUrl);
      }
      setIFrameHeight('320px');
      const formContainer = document.getElementById('gFormsContainer');
      if (formContainer) {
        setTimeout(() => {
          // scroll to the container after form is submitted
          formContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    } else if (iframeCounter > 2) {
      //another form request
      setIFrameCounter(1);
      setIFrameHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeCounter]);

  const handleOnLoad = () => {
    setIFrameCounter((prev) => prev + 1);
  };

  return (
    <div id="gFormsContainer" className="flex flex-col items-center justify-center w-full h-full p-3 pt-0 relative">
      <Text variant="heading-2" className="font-semibold text-center m-4 top-[5rem] z-20">
        {title}
      </Text>
      <div className="w-full h-full relative">
        <iframe
          ref={iframeRef}
          title="g-form"
          id="gform"
          src={iframeUrl}
          width={width || '100%'}
          style={{ height: iFrameheight || '100vh' }}
          className="overflow-hidden border-none"
          onLoad={handleOnLoad}
        />
      </div>
    </div>
  );
};

export default Renderer({ name: 'moduleGenericFormsContainer' })(GenericGoogleForm);
