import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';

import { DownloadProps } from './types';

import s from './Download.module.scss';

const Download: FC<DownloadProps> = ({ title, eyebrow, steps, qrCode, storeButtons }) => {
  return (
    <div className="flex items-center justify-center bg-gray-100 py-10">
      <div className="flex flex-col lg:flex-row lg:items-stretch justify-between bg-white rounded-lg shadow-md max-w-screen-md w-full p-8">
        {/* Left Column */}
        <div className="flex-1 pr-6">
          {title && (
            <Text color="var(--purple)" className={cn('mb-4', s.subheader)} variant="header-4">
              {title}
            </Text>
          )}
          {eyebrow && (
            <Text color="var(--eggplant)" className={cn('mb-6')} variant="eyebrow">
              {eyebrow}
            </Text>
          )}
          {steps && (
            <div className="flex flex-col gap-4">
              {steps.map(({ id, content }) => (
                <div key={id} className="flex items-center gap-2 mb-1">
                  {renderImage(
                    { url: content.icon?.url, alt: content.icon?.alt || 'Icon' },
                    { className: cn(s.icon), 'aria-hidden': true, loading: 'lazy' }
                  )}
                  <Text color="var(--eggplant)" variant="base">
                    {content.description}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Right Column */}
        <div
          className={cn(
            'flex flex-col items-center flex-1',
            qrCode && storeButtons?.length ? 'justify-between' : 'justify-center'
          )}
        >
          {qrCode && (
            <div className="mb-6">
              <img src={qrCode.url} alt={qrCode.alt || 'QR Code'} className="w-48 h-48 mx-auto" />
            </div>
          )}
          <div className="flex gap-4">
            {storeButtons?.map(
              ({ id, content }) =>
                content.image && (
                  <a key={id} href={content.url} target="_blank" rel="noopener noreferrer">
                    <img src={content.image.url} alt={content.image.alt || 'Store Button'} className="w-32" />
                  </a>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Renderer({ name: 'moduleDownload' })(Download);
