import cn from 'classnames';
import { FC } from 'react';

import { Video } from '@commerce/types/common';
import RichText from '@components/common/RichText/RichText';
import { ModulePillBoxItem, STYLE_VARIANT } from '@components/common/types/ModulePillBoxes';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Text from '@components/ui/Text/Text';

import s from './PillBoxItem.module.scss';

const PillBoxItem: FC<ModulePillBoxItem> = (props) => {
  const {
    buttonText,
    image,
    bottomText,
    header,
    styleVariant,
    index,
    video,
    defaultAlignment,
    roundCorners = true,
  } = props;

  const isSlider = styleVariant === STYLE_VARIANT.SLIDER;

  const imageOrVideo = image || video;

  const textPosition = defaultAlignment ? 'text-left' : 'text-center';

  return (
    <section className={cn(s.pillContainer, { [s.pillContainerSlider]: isSlider })}>
      {!isSlider && (
        <div className={s.buttonContainer}>
          <div className={s.circle}>{index}</div>
          <Text variant="heading-2" className={s.buttonText} asElement="h2">
            {buttonText}
          </Text>
        </div>
      )}
      {imageOrVideo && (
        <div
          className={cn(s.imageContainer, { [s.imageContainerSlider]: isSlider }, { [s.roundCorners]: roundCorners })}
        >
          <ImageOrVideo
            className={s.image}
            type={image ? 'image' : (imageOrVideo as Video).type}
            {...imageOrVideo}
            loading="lazy"
            width={308}
            height={403}
          />
        </div>
      )}
      <div className={cn({ [s.textContainerSlider]: isSlider })}>
        {isSlider && header && (
          <Text variant="heading-2" className={cn(s.header, textPosition)} asElement="h2">
            {header}
          </Text>
        )}
        {bottomText && (
          <RichText
            className={cn(s.bottomText, { [s.bottomTextSlider]: isSlider }, textPosition)}
            document={bottomText}
          />
        )}
      </div>
    </section>
  );
};

export default PillBoxItem;
