import cn from 'classnames';

import {
  FeaturedTriBulletsContainer,
  FeaturedTriBulletsContainerContent,
} from '@components/common/types/FeaturedTriBulletsContainer';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { getContentfulImgUrl, renderImage } from '@lib/image';

import s from './FeatureTriBullets.module.scss';

interface Props extends FeaturedTriBulletsContainer {
  className?: string;
}

interface RendererProps extends FeaturedTriBulletsContainerContent {
  className?: string;
}

const Feature = ({ title, description, moduleFeaturedTriBullets: features }: FeaturedTriBulletsContainer) => (
  <Section>
    <div className={cn('content', s.wrapper)}>
      {title && (
        <Text variant="heading-1" className={s.title} asElement="h2">
          {title}
        </Text>
      )}
      {description && <Text variant="text-2" html={description} className={s.description} />}
    </div>
    <div className={s.featureWrapper}>
      {features.map(({ internalName, headLine, icon, description: featureDescription }) => (
        <div key={internalName}>
          {headLine && (
            <Text variant="heading-3" asElement="h3" color="var(--grey-800)">
              {headLine}
            </Text>
          )}
          {icon?.url
            ? renderImage(
                { url: getContentfulImgUrl(icon.url, 100), alt: icon.alt || internalName },
                { className: s.icon }
              )
            : null}
          {featureDescription && (
            <Text
              variant="text-2"
              color="var(--text-secondary-2)"
              html={featureDescription}
              className={s.description}
            />
          )}
        </div>
      ))}
    </div>
  </Section>
);
const mapper = ({ moduleFeaturedTriBullets, ...rest }: RendererProps): Props => ({
  ...rest,
  moduleFeaturedTriBullets: mapContent(moduleFeaturedTriBullets),
});

export default Renderer({ name: 'moduleFeaturedTriBulletsContainer', mapper })(Feature);
