import { Entry } from '@services/contentful/types';
import { FC, Fragment } from 'react';

import { ModulePartner } from '@components/common/types/ModulePartner';
import { ContentModule } from '@components/common/types/Page';
import { Teaser } from '@components/common/types/Teaser';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { getContentfulImgUrl, renderImage } from '@lib/image';

import s from './FeaturedPartners.module.scss';

interface Props extends ContentModule {
  teaser?: Teaser;
  partners: ModulePartner[];
}

interface RendererProps extends ContentModule {
  teaser: Entry<Teaser>;
  partners: Entry<ModulePartner>[];
}

const FeaturedPartners: FC<Props> = ({ teaser, partners }) => (
  <Section>
    {teaser?.headline && (
      <Text variant="heading-4" className={s.headline} asElement="h2">
        {teaser?.headline}
      </Text>
    )}
    {teaser?.title && (
      <Text variant="heading-2" className={s.title} asElement="h3">
        {teaser?.title}
      </Text>
    )}
    <div className="flex justify-center items-center flex-wrap md:space-x-10 mt-8 md:mt-10">
      {partners.map(({ internalName, logo: { url, alt }, ctaUrl }) => (
        <Fragment key={internalName}>
          {url && (
            <a href={ctaUrl} aria-label={alt} className="cursor-pointer p-4">
              {renderImage({ url: getContentfulImgUrl(url, 240), alt })}
            </a>
          )}
        </Fragment>
      ))}
    </div>
  </Section>
);

const mapper = ({ teaser, partners, ...rest }: RendererProps): Props => ({
  ...rest,
  teaser: mapContent(teaser),
  partners: mapContent(partners),
});

export default Renderer({ name: 'moduleFeaturedPartners', mapper })(FeaturedPartners);
