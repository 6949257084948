import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, forwardRef, InputHTMLAttributes, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { getSubscriptionInfoFromSKU } from '@components/cart/helpers';
import { ModulePlan } from '@components/common/types/ModulePlan';
import { PremiumSource, ProductBrand, ProductType } from '@components/product/enums';
import Button from '@components/ui/Button/Button';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { useCustomer } from '@framework/customer';
import usePrice from '@framework/product/use-price';
import { AddToCartFn } from '@lib/hooks/useAddToCart';
import { useCanAddL360Premium } from '@lib/hooks/useCanAddL360Premium';
import { useHasL360PremiumInCart } from '@lib/hooks/useHasL360PremiumInCart';
import { useHasSubscriptionInCart } from '@lib/hooks/useHasSubscriptionInCart';
import { getContentfulImgUrl, renderImage } from '@lib/image';
import { isEmeaLocale } from '@lib/locales';

import radioStyle from '../../common/RadioTabs/RadioTab/RadioTab.module.scss';
import plansStyle from '../plans.module.scss';
import style from './ChoosePlans.module.scss';

const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(({ className, ...props }, ref) => {
  return <input {...props} ref={ref} type="radio" className={cn(className, radioStyle.radio, 'mr-4 lg:mr-2 mt-1')} />;
});
Radio.displayName = 'Radio';

type FormValues = {
  schedule: 'monthly' | 'yearly';
};

interface Props {
  plans: ModulePlan[];
  title: string | null;
  currencyCode: string;
  ctaText: string;
  onAddToCart: AddToCartFn;
  loading: boolean;
}

const Plan: FC<Omit<Props, 'plans' | 'title'> & { plan: ModulePlan }> = ({
  plan,
  currencyCode,
  ctaText,
  onAddToCart,
  loading: disabled,
}) => {
  const {
    yearlyProduct: { bigCommerceId, sku, name: productName, description, price: yearPrice },
    monthlyProduct,
    icon,
    name,
  } = plan;

  const { t } = useTranslation(['common', 'plans']);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting: loading },
  } = useForm<FormValues>({ defaultValues: { schedule: monthlyProduct ? 'yearly' : undefined } });
  const { isSubscriber } = useCustomer();
  const router = useRouter();
  const { locale = i18n.defaultLocale } = router;
  const renderCTA = !isEmeaLocale(locale);
  const hasSubscriptionInCart = useHasSubscriptionInCart();
  const hasL360PremiumInCart = useHasL360PremiumInCart();
  const canAddLife360Premium = useCanAddL360Premium();
  const isLife360Plan = useMemo(
    () => getSubscriptionInfoFromSKU(sku, locale)?.brand === ProductBrand.L360,
    [locale, sku]
  );

  const monthPrice = monthlyProduct ? monthlyProduct.price : yearPrice / 12;

  const { price: monthlyPrice } = usePrice({
    amount: Number(monthPrice),
    currencyCode,
  });

  const { price: yearlyPrice } = usePrice({
    amount: Number(yearPrice),
    currencyCode,
  });

  const { price: yearlyPriceByMonth } = usePrice({
    amount: Number(yearPrice) / 12,
    currencyCode,
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data: any) => {
      const productBrand = isLife360Plan ? ProductBrand.L360 : undefined;
      const addToCartResp = await (monthlyProduct && data.schedule === 'monthly'
        ? onAddToCart(
            monthlyProduct.bigCommerceId.toString(),
            monthlyProduct.sku,
            monthlyProduct.name,
            ProductType.DIGITAL,
            PremiumSource.PREMIUM_PAGE,
            productBrand
          )
        : onAddToCart(
            bigCommerceId.toString(),
            sku,
            productName,
            ProductType.DIGITAL,
            PremiumSource.PREMIUM_PAGE,
            productBrand
          ));

      if (addToCartResp && isLife360Plan) {
        router.push('/next-steps');
      }
    },
    [bigCommerceId, monthlyProduct, onAddToCart, productName, sku, isLife360Plan, router]
  );

  const getCtaMessage = (): string => {
    if (isSubscriber) {
      return t('plans:choosePlans.warning.alreadySubscribed');
    }
    if (hasSubscriptionInCart || hasL360PremiumInCart) {
      return t('plans:choosePlans.warning.alreadyInCart');
    }
    if (isLife360Plan && !canAddLife360Premium) {
      return t('plans:choosePlans.warning.unableToAddLife360');
    }

    return ctaText;
  };

  const disableCta =
    !bigCommerceId ||
    (disabled && !loading) ||
    isSubscriber ||
    hasSubscriptionInCart ||
    hasL360PremiumInCart ||
    (isLife360Plan && !canAddLife360Premium);

  return (
    <form className={style.plan} key={plan.yearlyProduct.bigCommerceId} onSubmit={handleSubmit(onSubmit)}>
      {icon?.url && renderImage({ url: getContentfulImgUrl(icon.url, 85), alt: icon.alt || name })}
      <Text variant="heading-2" color="var(--text-secondary)" className="mt-2 md:mb-1" asElement="h3">
        {name}
      </Text>
      <Text variant="text-2" color="var(--text-secondary-2)" className="mb-2 md:mb-4 text-center" html={description} />
      <Text variant="heading-3" className={style.price} weight="normal" color="var(--text-secondary)">
        {monthlyProduct ? (
          <fieldset aria-label="Schedule" className={style.comparison}>
            <legend className="sr-only">{name}</legend>
            <label className={style.comparisonLabel} htmlFor={`${plan.name}-schedule-monthly`}>
              <Radio {...register('schedule')} id={`${plan.name}-schedule-monthly`} value="monthly" />
              <div className={style.comparisonContent}>{t('common:price.monthly', { price: monthlyPrice })}</div>
            </label>

            <span className="text-accents-10 font-light">{t('common:price.or')}</span>

            <label className={style.comparisonLabel} htmlFor={`${plan.name}-schedule-yearly`}>
              <Radio {...register('schedule')} id={`${plan.name}-schedule-yearly`} value="yearly" defaultChecked />
              <div className={style.comparisonContent}>
                {t('common:price.yearly', { price: yearlyPrice })}
                <div className="block text-[12px] leading-4 text-purple font-semibold">
                  {t('common:price.bestValueMonthly', { price: yearlyPriceByMonth })}
                </div>
              </div>
            </label>
          </fieldset>
        ) : (
          <Trans
            i18nKey="common:price.yearlyWithBracketsMonthly"
            values={{ yearlyPrice, monthlyPrice }}
            className="text-center"
          />
        )}
      </Text>
      {renderCTA && (
        <Button
          className={style.button}
          variant="cta"
          aria-label={ctaText}
          disabled={disableCta}
          loading={loading}
          type="submit"
          data-cy={`choosePlan-${camelCase(name)}-button`}
        >
          {getCtaMessage()}
        </Button>
      )}
    </form>
  );
};

const ChoosePlans: FC<Props> = ({ plans, title, ctaText, onAddToCart, currencyCode, loading }) => (
  <Section>
    <Text variant="heading-2" className={plansStyle.heading}>
      {title}
    </Text>
    <div className="flex flex-col md:flex-row gap-m justify-center">
      {plans
        .filter((plan) => plan && plan.yearlyProduct?.bigCommerceId)
        .map((plan) => (
          <Plan
            key={plan.yearlyProduct.bigCommerceId}
            plan={plan}
            ctaText={ctaText}
            currencyCode={currencyCode}
            onAddToCart={onAddToCart}
            loading={loading}
          />
        ))}
    </div>
  </Section>
);

export default ChoosePlans;
