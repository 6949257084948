import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Image } from '@commerce/types/common';
import Pricing from '@components/product/Pricing/Pricing';
import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Link from '@components/ui/Link';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { trackEvent } from '@lib/gtag';

import { ModuleProduct } from '../types/ModuleProduct';

import s from './PromoBumper.module.scss';

interface Props {
  currencyCode?: string;
  headline: string;
  eyebrow?: string;
  body: string;
  product?: Entry<ModuleProduct>;
  ctaTitle: string;
  ctaUrl: string;
  images?: Image[];
  mobileImages?: Image[];
  lazyLoadImage?: boolean;
  backgroundColor?: string;
  eyebrowColor?: string;
  textColor?: string;
  ctaBackgroundColor?: string;
  ctaTextColor?: string;
}

const getClassName = (isMobile = false, doubleImage = false): string =>
  cn({
    [s.imageMobile]: isMobile,
    [s.image]: !isMobile,
    [s.doubleImage]: doubleImage,
  });

const PromoBumper: FC<Props> = ({
  currencyCode,
  headline,
  eyebrow,
  body,
  product,
  ctaTitle,
  ctaUrl,
  images,
  mobileImages,
  lazyLoadImage = false,
  backgroundColor,
  eyebrowColor,
  textColor,
  ctaBackgroundColor,
  ctaTextColor,
}) => {
  const { price, salePrice } = product?.content || {};
  const showPricing = typeof price !== 'undefined' && typeof salePrice !== 'undefined';
  const singleImage = images?.length === 1;

  const clickHandler = () => {
    trackEvent({ action: 'click', category: 'promoBumper' });
  };

  return (
    <Section>
      <Link className={cn(s.wrapper)} href={ctaUrl} onClick={clickHandler}>
        {images?.[0] && (
          <ImageOrVideo
            type="image"
            {...images[0]}
            className={getClassName(undefined, !singleImage)}
            containerClassName={s.imageContainer}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
          />
        )}
        {mobileImages?.[0] && (
          <ImageOrVideo type="image" {...mobileImages[0]} className={getClassName(true, !singleImage)} loading="lazy" />
        )}
        <div
          className={cn(s.contentWrapper, { [s.single]: singleImage })}
          style={{ backgroundColor: `var(--${backgroundColor || 'white'})` }}
        >
          <div className={s.textContent}>
            {eyebrow && (
              <Text
                variant="eyebrow"
                className={cn(s.eyebrow, 'mb-1 text-center')}
                asElement="span"
                color={`var(--${eyebrowColor || 'purple'})`}
              >
                {eyebrow}
              </Text>
            )}
            <Text
              variant="header-4"
              className={cn('mb-4 text-center', s.headline)}
              asElement="h2"
              color={`var(--${textColor || 'pitch-black'})`}
            >
              {headline}
            </Text>
            <Text
              variant="base"
              className={cn('text-center mb-4', s.body)}
              color={`var(--${textColor || 'pitch-black'})`}
            >
              {body}
            </Text>
            {showPricing && (
              <Pricing originalPrice={price} price={salePrice} currencyCode={currencyCode} className={s.pricing} />
            )}
          </div>
          <Button
            variant="cta"
            className={s.cta}
            style={{
              backgroundColor: `var(--${ctaBackgroundColor || 'purple'})`,
              color: `var(--${ctaTextColor || 'white'})`,
            }}
          >
            {ctaTitle}
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
        {images?.[1] && (
          <ImageOrVideo
            type="image"
            {...images[1]}
            className={getClassName(undefined, !singleImage)}
            containerClassName={s.imageContainer}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
          />
        )}
        {mobileImages?.[1] && (
          <ImageOrVideo
            type="image"
            {...mobileImages[1]}
            className={getClassName(true, !singleImage)}
            loading={lazyLoadImage ? 'lazy' : 'eager'}
          />
        )}
      </Link>
    </Section>
  );
};

export default Renderer({ name: 'modulePromoBumper' })(PromoBumper);
