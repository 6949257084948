import { Entry } from '@services/contentful/types';
import { FC } from 'react';

import FeaturedProduct from '@components/common/FeaturedProduct/FeaturedProduct';
import { ModuleProduct } from '@components/common/types/ModuleProduct';
import { Teaser } from '@components/common/types/Teaser';
import { Renderer } from '@components/screen/factory';
import Section from '@components/ui/Section/Section';

interface Props {
  internalName?: string;
  currencyCode?: string;
  locale: string;
  moduleTeaser?: Entry<Teaser>;
  moduleProduct?: Entry<ModuleProduct>;
}

const FeatureProductRenderer: FC<Props> = ({ internalName, currencyCode, locale, moduleProduct, moduleTeaser }) => (
  <Section>
    <FeaturedProduct
      currencyCode={currencyCode}
      locale={locale}
      internalName={internalName}
      moduleProduct={moduleProduct?.content}
      moduleTeaser={moduleTeaser?.content}
    />
  </Section>
);

export default Renderer({ name: 'moduleFeaturedProduct' })(FeatureProductRenderer);
