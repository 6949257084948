import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { isVideo } from '@lib/video';

import style from './Interactive.module.scss';

interface Item {
  content: {
    header: string;
    itemDescription: string;
    icon?: Image;
    media: Image | Video;
    videoThumbnail: Image;
  };
}

interface InteractiveProps {
  header: string;
  description: string;
  cta: string;
  ctaUrl: string;
  itemAlignment: 'right' | 'left';
  items: Item[];
  intervalDuration?: number;
  transitionDuration?: number;
}

const Interactive: FC<InteractiveProps> = ({
  header,
  description,
  cta,
  ctaUrl,
  itemAlignment,
  items,
  intervalDuration = 3000,
  transitionDuration = 500,
}) => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const [isAutoplayPaused, setIsAutoplayPaused] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    // Pause the video when the active item changes
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [activeItem]);

  useEffect(() => {
    if (isAutoplayPaused || !items || items.length === 0) {
      return; // Exit early if autoplay is paused or items is not available or empty
    }

    const interval = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setActiveItem((prevItem) => (prevItem + 1) % items.length);
        setTransitioning(false);
      }, transitionDuration);
    }, intervalDuration);

    // Clean up interval on component unmount
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [items, isAutoplayPaused, intervalDuration, transitionDuration]);

  const handleItemClick = (index: number) => {
    if (activeItem !== index) {
      setTransitioning(true);
      setTimeout(() => {
        setActiveItem(index);
        setTransitioning(false);
      }, transitionDuration);
    }
  };

  const handleMouseToggle = (isPaused: boolean) => {
    if (!videoPlaying) {
      setIsAutoplayPaused(isPaused);
    }
  };

  const columnOrderClasses = !itemAlignment ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col-reverse md:flex-row';

  const handleVideoPlayStateChange = useCallback((isPlaying: boolean) => {
    setVideoPlaying(isPlaying);
    setIsAutoplayPaused(isPlaying);
  }, []);

  const memoizedMedia = useMemo(() => {
    return items.reduce(
      (acc, item, index) => {
        if (isVideo(item.content.media.type)) {
          acc[index] = (
            <ImageOrVideo
              key={item.content.header}
              thumbnail={item.content.videoThumbnail?.url}
              type={item.content.media.type as string}
              {...item.content.media}
              preload="none"
              onVideoPlayStateChange={handleVideoPlayStateChange}
              className="w-full h-full object-cover"
            />
          );
        } else {
          acc[index] = (
            <ImageOrVideo
              key={item.content.header}
              type="image"
              {...item.content.media}
              loading="lazy"
              className="w-full h-full object-cover"
            />
          );
        }
        return acc;
      },
      {} as Record<number, JSX.Element>
    );
  }, [items, handleVideoPlayStateChange]);

  const currentMedia = memoizedMedia[activeItem];

  return (
    <Section className={style.wrap}>
      <div
        className="flex w-full md:w-4/5 lg:w-2/3 flex-col gap-4"
        onMouseEnter={() => handleMouseToggle(true)}
        onMouseLeave={() => handleMouseToggle(false)}
      >
        {header && (
          <Text variant="header-2" asElement="h2" weight="bold" className={style.header}>
            {header}
          </Text>
        )}
        {description && (
          <Text variant="header-4" asElement="p" weight="normal" className={style.description}>
            {description}
          </Text>
        )}

        {cta && ctaUrl && (
          <Button href={ctaUrl} width={160} type="button" size="md" variant="cta" className={style.cta}>
            {cta}
          </Button>
        )}
      </div>

      {items && items.length > 0 && (
        <div className={`mt-6 md:mt-8 lg:mt-10 w-full flex items-start gap-6 md:gap-4 lg:gap-0 ${columnOrderClasses}`}>
          {/* Left Column */}
          <div
            className={`${style.textContainer} w-full md:w-1/2 ${
              !itemAlignment ? 'lg:pl-28' : 'lg:pr-28'
            } flex flex-col`}
            onMouseEnter={() => handleMouseToggle(true)}
            onMouseLeave={() => handleMouseToggle(false)}
          >
            {items.map((item, index) => {
              const { header: itemHeader, itemDescription, icon } = item.content;
              const isActive = activeItem === index;

              return (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  key={itemHeader}
                  className={cn(
                    style.collapse,
                    `flex flex-col cursor-pointer overflow-hidden transition-all duration-300 ease-in-out p-4 md:px-6 mb-2 md:mb-4 rounded-lg lg:rounded-2xl`,
                    {
                      [style.active]: isActive,
                    }
                  )}
                  onClick={() => handleItemClick(index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleItemClick(index);
                    }
                  }}
                >
                  <div className={`flex items-center ${isActive ? 'mb-2 md:mb-4' : ''}`}>
                    {icon && <img src={icon.url} alt={itemHeader} className="w-6 h-6 mr-2" />}
                    {itemHeader && (
                      <Text className="font-semibold" variant="header-5" asElement="h3">
                        {itemHeader}
                      </Text>
                    )}
                  </div>
                  <div
                    className={`transition-max-height duration-300 ease-in-out ${
                      isActive ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
                    }`}
                  >
                    {itemDescription && (
                      <Text variant="base" className={style.itemDescription} html={itemDescription} asElement="div" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Right Column */}
          <div
            className="w-full md:w-1/2 flex items-center justify-center"
            onMouseEnter={() => handleMouseToggle(true)}
            onMouseLeave={() => handleMouseToggle(false)}
          >
            <div
              className={cn(
                `transition-opacity duration-500 ease-in-out`,
                {
                  'opacity-0': transitioning,
                  'opacity-100': !transitioning,
                },
                style.mediaContainer
              )}
            >
              {currentMedia}
            </div>
          </div>
        </div>
      )}
    </Section>
  );
};

export default Renderer({ name: 'moduleInteractive' })(Interactive);
