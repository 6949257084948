import { Entry } from '@services/contentful/types';
import React, { FC } from 'react';

import { ImageAlignType } from '@components/common/enums/ImageAlignType';
import { FeaturedTriBullets } from '@components/common/types/FeaturedTriBullets';
import { ContentModule } from '@components/common/types/Page';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Carousel from '@components/ui/Carousel/Carousel';
import ImageWithContent from '@components/ui/ImageWithContent';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { useMatchTailwindMedia } from '@lib/hooks/useMatchMedia';

import style from './plans.module.scss';

interface Props {
  title: string;
  fields: FeaturedTriBullets[];
}

interface RendererProps extends ContentModule {
  title: string;
  moduleSecondaryProducts: Entry<FeaturedTriBullets>[];
}

const renderContent = (headline: string, description: string) => (
  <div className="flex flex-col justify-center">
    <Text variant="heading-3" color="var(--text-secondary)" className="capitalize text-center mt-5 mb-3">
      {headline}
    </Text>
    <Text variant="text-2" color="var(--text-secondary-2)" className="text-center" html={description} />
  </div>
);

const FeatureDetails: FC<Props> = (props) => {
  const { title, fields } = props;
  const isMobile = useMatchTailwindMedia('md');

  return (
    <Section>
      <Text variant="heading-2" className={style.heading}>
        {title}
      </Text>
      {isMobile ? (
        <Carousel identifier="feature-details">
          {fields.map(({ internalName, headLine, icon, description }) => (
            <ImageWithContent
              key={internalName}
              source={{
                type: 'image',
                url: icon?.url || '',
                alt: icon?.description || icon?.alt || headLine,
              }}
              type={ImageAlignType.TOP}
              content={renderContent(headLine, description)}
            />
          ))}
        </Carousel>
      ) : (
        <div className="flex flex-row flex-wrap">
          {fields.map(({ headLine, icon, description }) => (
            <ImageWithContent
              key={headLine}
              source={{
                type: 'image',
                url: icon?.url || '',
                className: 'border-b-2',
                alt: icon?.description || icon?.alt || headLine,
              }}
              type={ImageAlignType.TOP}
              className="w-1/3 px-3"
              content={renderContent(headLine, description)}
            />
          ))}
        </div>
      )}
    </Section>
  );
};

const mapper = ({ moduleSecondaryProducts, ...rest }: RendererProps): Props => ({
  ...rest,
  fields: mapContent(moduleSecondaryProducts),
});

export default Renderer({ name: 'modulePlansBullets', mapper })(FeatureDetails);
