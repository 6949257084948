import { Entry } from '@services/contentful/types';
import { FC } from 'react';

import { ModulePlan, ModulePlanContent } from '@components/common/types/ModulePlan';
import { ModuelPlanFeatureContent, ModulePlanFeature } from '@components/common/types/ModulePlanFeature';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import { useAddToCart } from '@lib/hooks/useAddToCart';

import { getPlansWithoutContentObject } from '../helpers';
import ComparePlans from './ComparePlans';

interface RendererProps {
  title: string;
  description?: string;
  comparePlanFeatures: Entry<ModuelPlanFeatureContent>[];
  plans: Entry<ModulePlanContent>[];
}

type Props = Omit<RendererProps, 'plans' | 'comparePlanFeatures'> & {
  plans: ModulePlan[];
  planFeatures: ModulePlanFeature[];
};

const ComparePlansRenderer: FC<Props> = ({ title, description, plans, planFeatures }) => {
  const [loading, onAddToCart] = useAddToCart();

  return (
    <ComparePlans
      title={title}
      description={description}
      plans={plans}
      planFeatures={planFeatures}
      onAddToCart={onAddToCart}
      loading={loading}
    />
  );
};

const mapper = ({ comparePlanFeatures, plans, ...rest }: RendererProps): Props => ({
  ...rest,
  planFeatures: mapContent(comparePlanFeatures).map((item) => ({ ...item, planValues: mapContent(item.planValues) })),
  plans: getPlansWithoutContentObject(plans),
});

export default Renderer({ name: 'modulePlansCompare', mapper })(ComparePlansRenderer);
