import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import Link from '@components/ui/Link';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import { getContentfulImgUrl, renderImage } from '@lib/image';

import { Seatbelt as SeatbeltProps } from '../types/Seatbelt';

import s from './Seatbelt.module.scss';

const Seatbelt: FC<SeatbeltProps> = ({ title, items, allowHorizontalScroll }) => (
  <Section mobileNoPadding>
    {title && (
      <Text variant="base-bold" className={cn(s.eyebrow, 'mb-4 md:mb-8 px-6 text-center')} asElement="h2">
        {title}
      </Text>
    )}
    <div className={cn(s.seatbeltWrapper, allowHorizontalScroll && s.horizontalScroll, 'flex gap-5 mx-auto px-4 py-3')}>
      {items.map(({ content: { internalName, image, title: itemTitle, ctaOpenNewTab = false, ctaUrl } }) => (
        <Link
          target={ctaOpenNewTab ? '_blank' : '_self'}
          href={ctaUrl}
          key={internalName}
          className={cn(s.item, 'p-4 rounded-md')}
          data-cy="seatbelt-item"
        >
          {image?.url
            ? renderImage(
                { url: getContentfulImgUrl(image.url, 140), alt: image.alt || itemTitle },
                { className: 'w-full', width: 124, height: 124 }
              )
            : null}
          <Text variant="text-4" className={cn(s.itemTitle, 'mt-2 text-center text-eggplant')}>
            {itemTitle}
          </Text>
        </Link>
      ))}
    </div>
  </Section>
);

export default Renderer({ name: 'moduleSeatbelt' })(Seatbelt);
