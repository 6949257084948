export type ModuleCtaInterface = {
  internalName: string;
  backgroundColor: string;
  infoText?: string;
  infoTextColor?: string;
  infoTextPosition: InfoTextPosition;
  ctaText?: string;
  ctaColor?: string;
  ctaTextColor?: string;
  ctaLink?: string;
};

export enum InfoTextPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
