import cn from 'classnames';
import { FC, MouseEventHandler } from 'react';

import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Section from '@components/ui/Section/Section';
import Text from '@components/ui/Text/Text';
import extractColorHex from '@lib/extract-color-hex';

import { InfoTextPosition, ModuleCtaInterface } from '../types/ModuleCta';

import styles from './ModuleCta.module.scss';
interface Props extends Omit<ModuleCtaInterface, 'internalName'> {
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}

const ModuleCta: FC<Props> = ({
  backgroundColor,
  infoText,
  infoTextColor,
  infoTextPosition,
  ctaText,
  ctaColor,
  ctaTextColor,
  ctaLink,
  onClick,
}) => {
  const bgColor = (backgroundColor && extractColorHex(backgroundColor)) ?? 'var(--poolside-teal)';
  const color = (infoTextColor && extractColorHex(infoTextColor)) ?? 'var(--eggplant)';
  const ctaBgColor = (ctaColor && extractColorHex(ctaColor)) ?? 'var(--purple)';
  const ctaFontColor = (ctaTextColor && extractColorHex(ctaTextColor)) ?? 'var(--white)';
  const position = Object.values(InfoTextPosition).includes(infoTextPosition?.toLowerCase() as InfoTextPosition)
    ? infoTextPosition?.toLowerCase()
    : InfoTextPosition.Left;

  // Elements always positioned as column by center (text above button) on mobile devices
  // But position can be set in Contentful for desktop as Left, Right or Center.
  const elementsDirection: { [key: string]: { flexDirection: string; infoTextMargin: string } } = {
    left: { flexDirection: 'md:flex-row', infoTextMargin: 'md:mx-7 md:mb-0' },
    right: { flexDirection: 'md:flex-row-reverse', infoTextMargin: 'md:mx-7 md:mb-0' },
    center: { flexDirection: 'md:flex-col', infoTextMargin: 'md:mb-5' },
  };

  const onBtnClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (e) => {
    if (onClick) onClick(e);
  };

  return (
    <Section className={cn('module-cta', styles.ctaSection)} style={{ backgroundColor: bgColor }}>
      <div className={cn('flex flex-col items-center justify-center', elementsDirection[position].flexDirection)}>
        {infoText && (
          <Text
            className={cn('mb-5', elementsDirection[position].infoTextMargin)}
            color={color}
            variant="large"
            data-testid="module-cta-text"
          >
            {infoText}
          </Text>
        )}
        {ctaLink && ctaText && (
          <Button
            variant="cta"
            href={ctaLink}
            data-testid="module-cta-button"
            className="w-full md:w-auto"
            style={{ backgroundColor: ctaBgColor, color: ctaFontColor }}
            onClick={onBtnClick}
          >
            {ctaText}
          </Button>
        )}
      </div>
    </Section>
  );
};

export default Renderer({ name: 'moduleCta' })(ModuleCta);
