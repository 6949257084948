export const webAppLink = (baseUrl: string, period: string | undefined, name: string | undefined): string => {
  const url = new URL(baseUrl);

  if (name) {
    url.searchParams.set(
      'plan',
      /gold/i.test(name) ? 'gold' : /platinum/i.test(name) ? 'platinum' : /silver/i.test(name) ? 'silver' : 'free'
    );
    url.searchParams.set('period', /yea|annual/i.test(name) ? 'year' : 'month');
  }

  if (period) {
    url.searchParams.set('period', /yea|annual/i.test(period) ? 'year' : 'month');
  }

  return url.toString();
};
